import {
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  ISlideShowContainerOwnSDKFactory,
  SlideShowContainerProps,
  ISlideShowContainerSDK,
} from '../SlideShowContainer.types';

const _ownSDKFactory: ISlideShowContainerOwnSDKFactory = ({
  sdkData,
  setStyles,
  props,
  metaData,
}) => {
  const _state = {
    showSlideButtons: sdkData.showSlideButtons,
    showNavigationButtons: sdkData.showNavigationButtons,
  };

  const sdkProps = {
    get currentIndex() {
      return props.currentSlideIndex;
    },
    get showSlideButtons() {
      return _state.showSlideButtons;
    },
    set showSlideButtons(show: boolean) {
      setStyles({
        '--nav-dot-section-display': show
          ? 'block !important'
          : 'none !important',
      });
      _state.showSlideButtons = show;
    },
    get showNavigationButtons() {
      return _state.showNavigationButtons;
    },
    set showNavigationButtons(show: boolean) {
      setStyles({
        '--nav-button-display': show ? 'block !important' : 'none !important',
      });
      _state.showNavigationButtons = show;
    },
    get type() {
      return '$w.Slideshow';
    },
    toJSON() {
      const { currentIndex, type } = sdkProps;
      return {
        ...toJSONBase(metaData),
        type,
        currentIndex,
        showNavigationButtons: _state.showNavigationButtons,
        showSlideButtons: _state.showSlideButtons,
      };
    },
  };

  return sdkProps;
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {
    showSlideButtons: {
      type: ['boolean'],
    },
    showNavigationButtons: {
      type: ['boolean'],
    },
  },
});

export const sdk = composeSDKFactories<
  SlideShowContainerProps,
  ISlideShowContainerSDK
>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
  ownSDKFactory,
);
